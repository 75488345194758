import styles from './TabContentIHB.module.scss';

import { Icon, Modal, ResponsiveImage } from '@/ui/base';
import { convertMarkdown, ComponentCtaButton } from '@/ui';
import type { ImageHeadingBodyItem } from '@/types';
import { colorPickerHexToClassName } from '@/ui/constants';
import { useState } from 'react';

const TabContentIHB = ({
  sys,
  ihbHeading,
  ihbHeadline,
  ihbBody,
  ihbImage,
  ihbCta,
  ihbImageCaption,
  pillarColour,
  splitText,
  enableLightbox,
}: ImageHeadingBodyItem & {
  splitText?: boolean;
}) => {
  const lightBox = enableLightbox && ihbImage;
  const [isImageOpen, setIsImageOpen] = useState(false);
  const styleColour = pillarColour
    ? colorPickerHexToClassName[pillarColour]
    : '';

  const heading = ihbHeadline ?? ihbHeading;

  const id = `ihb-${sys.id}`;

  const handleImageClick = () => {
    if (lightBox) {
      setIsImageOpen(true);
    }
  };

  function renderIhbBody(ihbBody: string) {
    if (!ihbImage && splitText) {
      return (
        <div className={styles.textContent}>{convertMarkdown(ihbBody)}</div>
      );
    } else {
      return convertMarkdown(ihbBody);
    }
  }

  return (
    <div
      className={`${styles.ihbContent} ${!ihbImage ? styles.noImage : ''} ${
        splitText ? styles.splitText : ''
      }`}
    >
      <div className={styles.ihbContent__content}>
        <>
          <div>
            {!!heading && (
              <h3 className={styles.ihbContent__heading}>{heading}</h3>
            )}
            {!!ihbBody && (
              <div
                className={`${styles.ihbContent__copy} ${
                  !ihbImage && splitText ? styles.textColumns : ''
                }`}
              >
                {renderIhbBody(ihbBody)}
              </div>
            )}
          </div>
          {ihbCta && (
            <div className={styles.ihbContent__cta}>
              <ComponentCtaButton {...ihbCta} ctaType='link' />
            </div>
          )}
        </>
      </div>
      <div className={styles.ihbContent__imageBlock}>
        <div
          className={`${styles.ihbContent__imageContainer} ${
            lightBox ? styles.ihbContent__lightbox : ''
          }`}
        >
          {ihbImage && (
            <ResponsiveImage
              media={ihbImage}
              sizes={{ sm: 425, md: 800, lg: 948 }}
              className={styles.ihbContent__image}
              onClick={lightBox ? handleImageClick : undefined}
            />
          )}
        </div>
        {ihbImage && ihbImageCaption && (
          <div
            className={`${styles.ihbContent__caption} ${styles[styleColour]}`}
          >
            <span>{ihbImageCaption}</span>
          </div>
        )}
      </div>
      {lightBox && (
        <Modal
          handleClose={() => setIsImageOpen(false)}
          isOpen={isImageOpen}
          triggerId={id}
          fullContent
          isLightBox
        >
          <div className={styles.mediaCard__lightboxContainer}>
            <div className={'loading'}>
              <Icon
                id='loading'
                className={`${styles.loadingIcon}`}
                width={44}
                height={44}
              />
            </div>
            <ResponsiveImage
              sizes={{ sm: 1600, md: 1600, lg: 1600 }}
              className={styles.mediaCard__lightboxModalImage}
              media={ihbImage}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TabContentIHB;
