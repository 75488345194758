import styles from './TabTitle.module.scss';

interface ITabTitle {
  active: boolean;
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
}

const TabTitle = ({ active, title, setSelectedTab, index }: ITabTitle) => {
  return (
    <li className={styles.tabTitle}>
      <button
        type='button'
        className={`${styles.tabTitle__link} ${active && styles.active}`}
        onClick={() => setSelectedTab(index)}
      >
        {title}
      </button>
    </li>
  );
};

export default TabTitle;
