import { ReactNode } from "react"

interface Tab {
  title: string;
  children: ReactNode;
}

const Tab = ({ children }: Tab) => {
  return <>{children}</>
}

export default Tab;
