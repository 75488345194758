import styles from './ComponentFeatureTabs.module.scss';

import Tab from "./Tab";
import Tabs from "./Tabs";
import TabContentIHB from "./TabContentIHB";
import type { ImageHeadingBodyItem } from '@/types';

const FeatureTabs = ({
  tabbedTitle,
  tabbedStyle,
  tabbedContent,
  splitTabbedContentText,
}: {
  tabbedTitle: string;
  tabbedStyle: string;
  tabbedContent: ImageHeadingBodyItem[];
  splitTabbedContentText: boolean;
}) => {
  return (
    <div
      key={tabbedTitle}
      className={`${styles.featureTabs} ${
        tabbedStyle.toLowerCase() === 'light' ? styles.light : styles.accent
      } `}
    >
      <div className={styles.featureTabs__container}>
        <Tabs>
          {tabbedContent?.map((tab: ImageHeadingBodyItem, index: number) => (
            <Tab
              key={'key_' + tab.ihbHeading + '_' + index}
              title={tab.ihbHeading}
            >
              <TabContentIHB {...tab} splitText={splitTabbedContentText} />
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default FeatureTabs;
